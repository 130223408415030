import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import '../common/animateline.css';
import Container from './Container';

export default function Appointment({ appointment, setModalCall }) {
  return (
    <section
      className="py-[30px] bg-[linear-gradient(90.35deg,_#012666_-1.2%,_#030D1E_103.94%)] text-center;
    "
    >
      <Container>
        <div className="grid grid-cols-12">
          <div className="md:flex hidden col-span-2 flex flex-wrap items-end">
            <div className="flex flex-wrap items-end w-full relative bottom-[10%] pr-10">
              <div className="flex w-full">
                <div className="mr-4 animateline h-[10px] mb-8 w-[45px] rounded-[30px] overflow-hidden relative">
                  <span className="h-[10px] w-[45px] rounded-[30px] bg-[#FFA030] absolute delay-150"></span>
                </div>
                <div className="animateline h-[10px] mb-8 w-full rounded-[30px] overflow-hidden relative">
                  <span className="h-[10px] w-[100%] rounded-[30px] bg-[#FFA030] absolute delay-150"></span>
                </div>
              </div>
              <div className="flex gap-5 w-full">
                <span className="inline-block w-[45px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
                <span className="inline-block w-[45px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
                <span className="inline-block w-[30px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
              </div>
            </div>
          </div>
          <div className="md:col-span-8 col-span-12">
            {appointment?.titleEditor && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className={`text-center  md:font-[400] font-[700] xl:text-[40px] md:text-[32px] sm:text-[26px] text-[22px] title_hd leading-tight text-[#fff] ${appointment?.titleEditor && 'mb-[30px]'}`}
                source={appointment?.titleEditor}
              >
                {appointment?.titleEditor}
              </ReactMarkdown>
            )}
            <div className="text-center">
              {appointment?.btn_link ? (
                <Link
                  className={`mx-auto bg-[#FFA030] text-[#000D3A] border border-[#fff] group inline-flex h-min items-center justify-center relative rounded overflow-hidden lg:px-14 lg:py-2 px-4 py-2 shadow-[10px_20px_40px_0px_#00000033]`}
                  href={`${appointment?.btn_link}`}
                >
                  <span
                    className={`bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1`}
                  ></span>
                  <span
                    // style={{textShadow: "1px 1px 4px #1113"}}
                    className={`lg:text-[24px] text-[14px] group-hover:text-black flex items-center font-[600] relative`}
                  >
                    {appointment?.btn_text}
                  </span>
                </Link>
              ) : (
                <button
                  className={`${appointment?.btn_text && 'mt-10'} mx-auto md:px-0 px-12 bg-[#FFA030] text-[#000D3A] border border-[#fff] group flex h-min items-center justify-center relative md:w-auto w-9/12 rounded overflow-hidden lg:px-14 lg:py-2 px-4 py-2 shadow-[10px_20px_40px_0px_#00000033]`}
                  onClick={() => setModalCall(appointment?.btn_text)}
                >
                  <span
                    className={`bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1`}
                  ></span>
                  <span
                    // style={{textShadow: "1px 1px 4px #1113"}}
                    className={`lg:text-[24px] text-[16px] group-hover:text-black flex items-center md:font-[600] font-[700] relative`}
                  >
                    {appointment?.btn_text}
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className="md:block hidden col-span-2">
            <div className="flex flex-wrap items-end w-full relative top-[10%] pl-10">
              <div className="flex w-full">
                <div className="animatelineright h-[10px] mb-8 w-full rounded-[30px] overflow-hidden relative">
                  <span className="h-[10px] w-[100%] rounded-[30px] bg-[#FFA030] absolute delay-150"></span>
                </div>
                <div className="mr-4 animatelineright h-[10px] mb-8 w-[45px] rounded-[30px] overflow-hidden relative">
                  <span className="h-[10px] w-[45px] rounded-[30px] bg-[#FFA030] absolute delay-150"></span>
                </div>
              </div>
              <div className="flex gap-5 w-full justify-end">
                <span className="inline-block w-[45px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
                <span className="inline-block w-[45px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
                <span className="inline-block w-[30px] h-[10px] bg-[#F5F5F5] rounded-[30px]"></span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
